import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { VALIDATION } from "../../../constants";

const Register = (props) => {
  const isMountedRef = useIsMountedRef();
  const { register } = useAuth();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        passwordConfirm: "",
        policy: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("error.emailInvalid"))
          .max(VALIDATION.EMAIL_MAXIMUM)
          .required(t("error.emailRequired")),
        firstname: Yup.string()
          .matches(
            /^([A-Z]|Å|Ä|Ö|É|Ó|Á|Ü|Ê|Ø)[a-zåäöéóáüêø]*( [A-Z][a-zåäöéóáüêø]*)*(-[A-Z][a-zåäöéóáüêø]*)?([^ ])$/,
            t("error.invalidFirstname")
          )
          .max(VALIDATION.FIRSTNAME_MAXIMUM)
          .required(t("error.firstnameRequired")),
        lastname: Yup.string()
          .matches(
            /^([A-Z]|Å|Ä|Ö|É|Ó|Á|Ü|Ê|Ø)[a-zåäöéóáüêø]*( [A-Z][a-zåäöéóáüêø]*)*(-[A-Z][a-zåäöéóáüêø]*)?([^ ])$/,
            t("error.invalidLastname")
          )
          .max(VALIDATION.LASTNAME_MAXIMUM)
          .required(t("error.lastnameRequired")),
        password: Yup.string()
          .min(VALIDATION.PASSWORD_MINIMUM)
          .max(VALIDATION.PASSWORD_MAXIMUM)
          .required(t("error.passwordRequired")),
        passwordConfirm: Yup.string()
          .min(VALIDATION.PASSWORD_MINIMUM)
          .max(VALIDATION.PASSWORD_MAXIMUM)
          .oneOf([Yup.ref("password")], t("error.passwordNotMatch"))
          .required(t("error.passwordConfirmRequired")),
        policy: Yup.boolean().oneOf([true], t("error.fieldChecked"))
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await register(
            values.email,
            values.firstname,
            values.lastname,
            values.password
          );

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.response.data.errorMessages });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            id="field-email"
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t("global.field.email.label")}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            id="field-password"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t("global.field.password.label")}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            id="field-password-confirm"
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            fullWidth
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            label={t("global.field.passwordConfirm.label")}
            margin="normal"
            name="passwordConfirm"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.passwordConfirm}
            variant="outlined"
          />
          <TextField
            id="field-firstname"
            error={Boolean(touched.firstname && errors.firstname)}
            fullWidth
            helperText={touched.firstname && errors.firstname}
            label={t("global.field.firstname.label")}
            margin="normal"
            name="firstname"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstname}
            variant="outlined"
          />
          <TextField
            id="field-lastname"
            error={Boolean(touched.lastname && errors.lastname)}
            fullWidth
            helperText={touched.lastname && errors.lastname}
            label={t("global.field.lastname.label")}
            margin="normal"
            name="lastname"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastname}
            variant="outlined"
          />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
              mt: 2
            }}
          >
            <Checkbox
              id="field-terms"
              checked={values.policy}
              color="primary"
              name="policy"
              onChange={handleChange}
            />
            <Typography color="textSecondary" variant="body2">
              {t("register.message.confirmTerms1")}
              <Link color="primary" component="a" href="#">
                {t("global.link.termsAndConditions")}
              </Link>
              {t("register.message.confirmTerms2")}
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>{errors.policy}</FormHelperText>
          )}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              {Array.isArray(errors.submit) ? (
                errors.submit.map((err) => (
                  <FormHelperText key={err} error>
                    {err}
                  </FormHelperText>
                ))
              ) : (
                <FormHelperText error>{errors.submit}</FormHelperText>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              id="btn-submit"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("global.button.register")}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Register;
