/* eslint-disable */

import Cookies from "universal-cookie";

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from "@material-ui/core";
import Flags from "country-flag-icons/react/3x2";

const LanguagePopover = () => {
  const anchorRef = useRef(null);
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);

    const cookies = new Cookies();
    cookies.set("language", language, { path: "/" });

    setOpen(false);
  };

  const languageOptions = {
    en: {
      icon: Flags.GB,
      label: t("languages.en")
    },
    se: {
      icon: Flags.SE,
      label: t("languages.sv")
    },
    no: {
      icon: Flags.NO,
      label: t("languages.no")
    },
    da: {
      icon: Flags.DK,
      label: t("languages.da")
    },
    de: {
      icon: Flags.DE,
      label: t("languages.de")
    },
    fr: {
      icon: Flags.FR,
      label: t("languages.fr")
    },
    es: {
      icon: Flags.ES,
      label: t("languages.es")
    },
    cimode: {
      icon: Flags.GB,
      label: t("global.language.cimode")
    }
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        data-cy="language-popover"
      >
        <Box
          sx={{
            display: "flex",
            height: 20,
            width: 20,
            "& img": {
              width: "100%"
            }
          }}
        >
          {selectedOption.icon}
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language) => {
          if (["cimode", "es", "fr", "de", "no", "da"].includes(language))
            return null;

          return (
            <MenuItem
              onClick={() => handleChangeLanguage(language)}
              key={language}
              data-cy={`language-popover-${language}`}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    display: "flex",
                    height: 20,
                    width: 20,
                    "& img": {
                      width: "100%"
                    }
                  }}
                >
                  {languageOptions[language].icon}
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {languageOptions[language].label}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default LanguagePopover;
