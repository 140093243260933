/* eslint-disable */
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import AccountPopover from "./dashboard/AccountPopover";
import LanguagePopover from "./dashboard/LanguagePopover";
import Logo from "./Logo";

const NavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none"
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const Navbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <NavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to="/">
          <Logo lightVersion />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <LanguagePopover />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavbarRoot>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default Navbar;
