// import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useParams } from "react-router";

import useAuth from "../../hooks/useAuth";
import FormAuth from "../../pages/authentication/FormAuth";
import FormRegister from "../../pages/authentication/FormRegister";

const FormGuard = (props) => {
  const { children, register } = props;
  const auth = useAuth();
  const location = useLocation();
  const { eventId } = useParams();

  // const [initialized, setInitialized] = useState(null);

  if (!auth.isAuthenticated) {
    if (register) return <FormRegister />;
    return <FormAuth />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (location.pathname !== `/event/${eventId}/apply`) {
    return <Navigate to={`/event/${eventId}/apply`} />;
  }

  return <>{children}</>;
};

FormGuard.propTypes = {
  children: PropTypes.node,
  register: PropTypes.bool
};

export default FormGuard;
