import { useRef } from "react";
import { Box } from "@material-ui/core";
import { Outlet } from "react-router";
import MailSidebar from "./MailSidebar";

const MailLayout = () => {
  const rootRef = useRef(null);

  return (
    <Box
      ref={rootRef}
      sx={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        flexGrow: 1
      }}
    >
      <MailSidebar containerRef={rootRef} />
      <Outlet />
    </Box>
  );
};

export default MailLayout;
