import { createContext, useEffect, useReducer } from "react";

import PropTypes from "prop-types";
import axios from "axios";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (csrfToken) => {
  if (csrfToken) {
    localStorage.setItem("csrfToken", csrfToken);
    axios.defaults.headers["x-csrf-token"] = csrfToken;
  } else {
    localStorage.removeItem("csrfToken");
    delete axios.defaults.headers["x-csrf-token"];
  }
};

const csrfPost = async (url, data, options) => {
  const response = await axios.post(url, data, options);
  const { _csrf, ...responseData } = response.data;

  if (responseData.success) {
    setSession(_csrf);
  }

  return { data: responseData };
};

const csrfDelete = async (url, data, options) => {
  const response = await axios.delete(url, data, options);
  const { _csrf, ...responseData } = response.data;

  if (responseData.success) {
    setSession(_csrf);
  }

  return { data: responseData };
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  UPDATE_USER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "Session",
  updateUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateAccountInfo: () => Promise.resolve(),
  emailChange: () => Promise.resolve(),
  passwordChange: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  removeAccount: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const csrfToken = window.localStorage.getItem("csrfToken");

        if (csrfToken) {
          setSession(csrfToken);

          const response = await axios.get("/api/me");
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const updateUser = async (user) => {
    dispatch({
      type: "UPDATE_USER",
      payload: {
        user
      }
    });
  };

  const login = async (email, password) => {
    const response = await csrfPost("/api/login", {
      email,
      password
    });
    const { user } = response.data;

    dispatch({
      type: "LOGIN",
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    const response = await axios.get("/api/logout");

    if (response.data.success) {
      setSession(null);
      dispatch({ type: "LOGOUT" });
    }
  };

  const register = async (email, firstname, lastname, password) => {
    const response = await csrfPost("/api/register", {
      firstname,
      lastname,
      password,
      email
    });
    const { user } = response.data;

    dispatch({
      type: "REGISTER",
      payload: {
        user
      }
    });
  };

  const updateAccountInfo = async (firstname, lastname) => {
    const response = await csrfPost("/api/user/update-info", {
      firstname,
      lastname
    });
    const { user } = response.data;

    updateUser(user);
  };

  const emailChange = async (newEmail, password) => {
    await csrfPost("/api/change-email", {
      newEmail,
      password
    });
  };

  const passwordRecovery = async (email) => {
    await csrfPost("/api/recover-password", {
      email
    });
  };

  const passwordReset = async (token, newPassword) => {
    await csrfPost("/api/reset-password", {
      token,
      newPassword
    });
  };

  const passwordChange = async (currentPassword, newPassword) => {
    await csrfPost("/api/change-password", {
      currentPassword,
      newPassword
    });
  };

  const removeAccount = async (password) => {
    await csrfPost("/api/remove-user", {
      password
    });
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Session",
        updateUser,
        login,
        logout,
        register,
        updateAccountInfo,
        emailChange,
        passwordChange,
        passwordRecovery,
        passwordReset,
        removeAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
export { csrfPost, csrfDelete };
