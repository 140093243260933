/* eslint-disable */
import { createContext, useReducer } from "react";
import EventUser from "../structures/eventUser";

import axios from "axios";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  event: null,
  eventUser: null,
  eventForm: null
};

const handlers = {
  RESET: (state, action) => {
    return {
      ...state,
      isInitialized: false
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, event, eventUser, eventForm } = action.payload;

    const eventUserObject = new EventUser(eventUser);

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      event: event,
      eventUser: eventUserObject,
      eventForm: eventForm
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const EventContext = createContext({
  ...initialState,
  initialize: (eventId) => Promise.resolve()
});

export const EventProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async (eventId) => {
    dispatch({ type: "RESET" });
    try {
      const response = await axios.get(`/api/event/${eventId}`);
      const { event, eventUser, eventForm } = response.data;

      dispatch({
        type: "INITIALIZE",
        payload: {
          event: event,
          eventUser: eventUser,
          eventForm: eventForm,
          isAuthenticated: true
        }
      });
    } catch (err) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          event: null,
          eventUser: null,
          eventForm: null,
          isAuthenticated: false
        }
      });
    }
  };

  return (
    <EventContext.Provider
      value={{
        ...state,
        initialize
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
