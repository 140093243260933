/* eslint-disable */

import { Drawer, Hidden } from "@material-ui/core";

import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile, children } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  if (!children) return null;

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280
            }
          }}
          variant="temporary"
        >
          {children}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280
            }
          }}
          variant="persistent"
        >
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
