/* eslint-disable */

import { Avatar, Box, Container, Divider, Typography } from "@material-ui/core";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CalendarIcon from "../../icons/Calendar";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import DashboardSidebar from "./DashboardSidebar";
import EventContext from "../../contexts/EventContext";
import HomeIcon from "../../icons/Home";
import SettingsIcon from "../../icons/Cog";
import InboxIcon from "../../icons/Inbox";
import FolderOpen from "../../icons/FolderOpen";
import MailIcon from "../../icons/Mail";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import SidebarContext from "../../contexts/SidebarContext";
import UsersIcon from "../../icons/Users";
import DocumentTextIcon from "../../icons/DocumentText";
import gtm from "../../lib/gtm";

const EventLayout = () => {
  const { isSidebarMobileOpen, setIsSidebarMobileOpen } =
    useContext(SidebarContext);
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  const { event, eventUser } = React.useContext(EventContext);

  const location = useLocation();

  if (!event) {
    return null;
  }

  const sections = [
    {
      title: t("event.sidebar.general.title"),
      items: [
        {
          title: t("global.page.feed"),
          label: "feed",
          path: ".",
          icon: <HomeIcon fontSize="small" />
        },
        {
          title: t("global.page.users"),
          label: "users",
          path: "./users",
          icon: <UsersIcon fontSize="small" />,
          roleLevels: [0, 1]
        },
        {
          title: t("global.page.files"),
          label: "files",
          path: "./files",
          icon: <FolderOpen fontSize="small" />
        },
        {
          title: t("global.page.mail.label"),
          label: "email",
          path: "./email",
          icon: <MailIcon fontSize="small" />,
          roleLevels: [0, 1]
        },
        // {
        //   title: t("global.page.scheduling"),
        //   label: "scheduling",
        //   path: "./scheduling",
        //   icon: <CalendarIcon fontSize="small" />,
        //   roleLevels: [0]
        // },
        // {
        //   title: t("global.page.statistics"),
        //   label: "statistics",
        //   path: "./statistics",
        //   icon: <ChartSquareBarIcon fontSize="small" />,
        //   roleLevels: [0]
        // },
        {
          title: t("global.page.controlPanel"),
          label: "control-panel",
          path: "./control-panel",
          icon: <SettingsIcon fontSize="small" />,
          roleLevels: [0]
        }
      ]
    }
  ];

  if (eventUser.hasRole([0, 1])) {
    sections.push({
      title: t("event.sidebar.recruitment.title"),
      items: [
        {
          title: t("global.page.applications"),
          label: "applications",
          path: "./applications",
          icon: <InboxIcon fontSize="small" />,
          roleLevels: [0, 1]
        },
        {
          title: t("global.page.formCreate"),
          label: "form",
          path: "./form",
          icon: <DocumentTextIcon fontSize="small" />,
          roleLevels: [0]
        }
      ]
    });
  }

  const sidebarContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              overflow: "hidden",
              p: 2
            }}
          >
            <RouterLink to="." style={{ textDecoration: "none" }}>
              <Avatar
                src={event.avatarUrl}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48
                }}
              >
                {event.name.charAt(0)}
              </Avatar>
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {event ? event.name : t("global.unknownEvent")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => {
            section.items = section.items.filter(
              (s) =>
                !s.hasOwnProperty("roleLevels") ||
                eventUser.hasRole(s.roleLevels)
            );

            return (
              <NavSection
                key={section.title}
                id={`event-sidebar-nav-section`}
                pathname={location.pathname}
                sx={{
                  "& + &": {
                    mt: 3
                  }
                }}
                {...section}
              />
            );
          })}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <Box
      id="event-layout"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexGrow: 1,
        flexDirection: "column"
      }}
    >
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      >
        {sidebarContent}
      </DashboardSidebar>
      <Outlet />
    </Box>
  );
};

export default EventLayout;
