export default class EventUser {
  constructor(eventUser) {
    this.eventUser = eventUser;
  }

  isGroupMember(groupId) {
    if (this.eventUser.getRole().roleLevel === 0) return true;

    if (
      this.eventUser.getRole().roleLevel === 1 ||
      this.eventUser.getRole().roleLevel === 2
    ) {
      return this.eventUser.role.eventGroup === groupId;
    }

    return false;
  }

  getGroup() {
    return this.eventUser.role.eventGroup;
  }

  getRole() {
    return this.eventUser.role.eventRole;
  }

  hasRole(roleLevel) {
    const role = this.getRole();

    if (Array.isArray(roleLevel)) return roleLevel.includes(role.roleLevel);

    return roleLevel === role.roleLevel;
  }
}
