/* eslint-disable */
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { useTranslation } from "react-i18next";
import { VALIDATION } from "../../../constants";

const Login = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("error.emailInvalid"))
          .max(VALIDATION.EMAIL_MAXIMUM)
          .required(t("error.emailRequired")),
        password: Yup.string()
          .max(VALIDATION.PASSWORD_MAXIMUM)
          .required("error.passwordRequired")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.response.data.errorMessages });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            id="field-email"
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t("global.field.email.label")}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            id="field-password"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t("global.field.password.label")}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              {Array.isArray(errors.submit) ? (
                errors.submit.map((err) => (
                  <FormHelperText key={err} error>
                    {err}
                  </FormHelperText>
                ))
              ) : (
                <FormHelperText error>{errors.submit}</FormHelperText>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              id="btn-submit"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("global.button.login")}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
