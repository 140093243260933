import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import qufusLogoLight from "./qufus_logo_light.png";
import qufusLogoDark from "./qufus_logo_dark.png";
import qufusLogoNature from "./qufus_logo_nature.png";
import qufusLogoOriginal from "./qufus_logo_original.png";

const Logo = ({ lightVersion = false }) => {
  const theme = useTheme();
  let logo;

  if (theme.palette.primary.main === "#01ab56") {
    logo = qufusLogoNature;
  } else if (theme.palette.mode === "dark") {
    logo = qufusLogoDark;
  } else if (lightVersion) {
    logo = qufusLogoLight;
  } else {
    logo = qufusLogoOriginal;
  }

  return <img src={logo} alt="Logo" height="35" />;
};

Logo.propTypes = {
  lightVersion: PropTypes.bool
};

export default Logo;
