/* eslint-disable */

import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import gtm from "../../lib/gtm";
import { Register } from "../../components/authentication/register";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FormRegister = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t("register.title")} ${t("global.helmetName")}`}</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}
      >
        <Container maxWidth="md" sx={{ py: "80px" }}>
          <Card
            sx={{
              p: 4
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h4">
                        {t("form.register.greeting")}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                      >
                        {t("form.register.message1")}
                      </Typography>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          "& img": {
                            width: "100%",
                            height: "auto"
                          }
                        }}
                      >
                        <img
                          alt="Register"
                          src="/static/form/introduce-3-rafiki.png"
                        />
                      </Box>
                    </div>
                  </Box>
                </CardContent>
              </Grid>
              <Grid item sm={6} xs={12}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Register />
                  <Box
                    sx={{
                      mt: 2
                    }}
                  >
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="../."
                      variant="body2"
                    >
                      {t("global.button.backToLogin")}
                    </Link>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default FormRegister;
