/* eslint-disable */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  format as formatDate,
  formatRelative,
  formatDistance,
  formatDistanceToNowStrict,
  formatDistanceToNow,
  isDate,
  differenceInCalendarYears
} from "date-fns";

import { en, sv as se, no, da, de, es, fr, zh } from "date-fns/locale";

import translationEN from "./locales/en.json";
import translationSE from "./locales/se.json";
import translationNO from "./locales/no.json";
import translationDA from "./locales/da.json";
import translationDE from "./locales/de.json";
import translationES from "./locales/es.json";
import translationFR from "./locales/fr.json";
import translationZHCN from "./locales/zh-CN.json";

const resources = {
  en: {
    translation: translationEN
  },
  se: {
    translation: translationSE
  },
  no: {
    translation: translationNO
  },
  da: {
    translation: translationDA
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  zh: {
    translation: translationZHCN
  }
};

const locales = { en, se, no, da, de, es, fr, zh }; // eslint-disable-line

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (isDate(value)) {
        const locale = locales[lng];

        if (format === "short") return formatDate(value, "P", { locale });
        if (format === "long") return formatDate(value, "PPPP", { locale });
        if (format === "relative")
          return formatRelative(value, new Date(), { locale });
        if (format === "ago")
          return formatDistance(value, new Date(), {
            locale,
            addSuffix: true
          });
        if (format === "distanceToNowStrict")
          return formatDistanceToNowStrict(value, {
            locale
          });
        if (format === "distanceToNow")
          return formatDistanceToNow(value, {
            locale
          });
        if (format === "yearsOld")
          return differenceInCalendarYears(new Date(), value, {
            locale
          });
        return formatDate(value, format, { locale });
      }

      return value;
    }
  }
});
