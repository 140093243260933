export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE"
};

export const VALIDATION = {
  PASSWORD_MINIMUM: 7,
  PASSWORD_MAXIMUM: 255,
  EMAIL_MAXIMUM: 255,
  FIRSTNAME_MAXIMUM: 40,
  LASTNAME_MAXIMUM: 40,
  EVENT_NAME_MINIMUM: 3,
  EVENT_NAME_MAXIMUM: 255
};
