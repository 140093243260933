/* eslint-disable */

import DashboardBreadcrumbs from "./DashboardBreadcrumbs";
import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core/styles";
import { Box, Grid, Hidden, IconButton } from "@material-ui/core";

const DashboardLayoutContent = experimentalStyled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px"
  },
  height: "100%",
  position: "relative",
  display: " flex",
  flexDirection: "column"
}));

const DashboardLayout = () => {
  return (
    <DashboardLayoutContent id="dashboard-layout">
      <Box sx={{ flexDirection: "row" }}>
        <DashboardBreadcrumbs />
      </Box>
      <Outlet />
    </DashboardLayoutContent>
  );
};

export default DashboardLayout;
