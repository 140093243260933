/* eslint-disable */

import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import gtm from "../../lib/gtm";
import FormLogin from "../../components/authentication/form/FormLogin";
import { useTranslation } from "react-i18next";

const FormAuth = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t("login.title")} ${t("global.helmetName")}`}</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}
      >
        <Container maxWidth="md" sx={{ py: "80px" }}>
          <Card
            sx={{
              p: 4
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h4">
                        {t("form.login.message.greeting")}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                      >
                        {t("form.login.message.loginRequired")}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                      >
                        {`${t("form.login.message.noAccount")} ${t(
                          "global.button.createNewAccount"
                        )}`}
                      </Typography>

                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          "& img": {
                            width: "100%",
                            height: "auto"
                          }
                        }}
                      >
                        <img alt="Login" src="/static/form/resume-rafiki.png" />
                      </Box>
                    </div>
                  </Box>
                </CardContent>
              </Grid>
              <Grid item sm={6} xs={12}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <FormLogin />
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default FormAuth;
