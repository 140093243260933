import { createContext } from "react";

import PropTypes from "prop-types";

const RoutesContext = createContext({ routes: null });

export const RoutesProvider = ({ routes, children }) => (
  <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>
);

RoutesProvider.propTypes = {
  routes: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default RoutesContext;
