/* eslint-disable */

import React, { useEffect } from "react";

import AccessDenied from "src/pages/AccessDenied";
import EventContext from "../../contexts/EventContext";
import PropTypes from "prop-types";
import { useParams } from "react-router";

const EventGuard = (props) => {
  const { children } = props;

  const { eventId } = useParams();

  const { isInitialized, isAuthenticated, initialize } =
    React.useContext(EventContext);

  useEffect(() => {
    initialize(eventId);
  }, [eventId]);

  if (!isInitialized) {
    return <></>;
  }

  if (isInitialized && !isAuthenticated) {
    return <AccessDenied />;
  }

  return <>{children}</>;
};

EventGuard.propTypes = {
  children: PropTypes.node
};

export default EventGuard;
