/* eslint-disable */

import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Divider, Link, Typography } from "@material-ui/core";
import { Login as LoginForm } from "../login";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const FormLogin = () => {
  const { platform } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          mb: 3
        }}
      >
        <div>
          <Typography color="textPrimary" gutterBottom variant="h4">
            {t("login.title")}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {t("login.message1")}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          flexGrow: 1
        }}
      >
        {platform === "Session" && <LoginForm />}
      </Box>
      <Divider sx={{ my: 3 }} />
      <Link
        color="textSecondary"
        component={RouterLink}
        to="./register"
        variant="body2"
      >
        <Button
          id="btn-register"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t("global.button.createNewAccount")}
        </Button>
      </Link>
      <Box
        sx={{
          mt: 2
        }}
      >
        <Link
          id="forgot-password-link"
          color="textSecondary"
          component={RouterLink}
          sx={{ mt: 1 }}
          to="/authentication/password-recovery"
          variant="body2"
        >
          {t("global.button.forgotPassword")}
        </Link>
      </Box>
    </>
  );
};

export default FormLogin;
