import { createContext, useState } from "react";

import PropTypes from "prop-types";

const SidebarContext = createContext({
  isSidebarMobileOpen: false,
  setIsSidebarMobileOpen: () => {}
});

export const SidebarProvider = (props) => {
  const { children } = props;
  const [isSidebarMobileOpen, setOpen] = useState(false);

  const setIsSidebarMobileOpen = (open) => {
    setOpen(open);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarMobileOpen,
        setIsSidebarMobileOpen
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SidebarConsumer = SidebarContext.Consumer;

export default SidebarContext;
