/* eslint-disable */

import { useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Hidden,
  List
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "../../../store";
import { closeSidebar } from "../../../slices/mail";
import MailLabel from "./MailLabel";
import { useTranslation } from "react-i18next";

const MailSidebarDesktop = experimentalStyled(Drawer)({
  minHeight: "100%",
  width: 280,
  "& .MuiDrawer-paper": {
    position: "relative"
  }
});

const MailSidebarMobile = experimentalStyled(Drawer)({
  "& .MuiBackdrop-root": {
    position: "absolute"
  },
  "& .MuiDrawer-paper": {
    position: "relative",
    width: 280
  }
});

const MailSidebar = ({ containerRef }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSidebarOpen } = useSelector((state) => state.mail);
  const { t } = useTranslation();

  useEffect(() => {
    if (isSidebarOpen) {
      dispatch(closeSidebar());
    }
  }, [location.pathname]);

  const handleCloseSidebar = () => {
    dispatch(closeSidebar());
  };

  const content = (
    <div>
      <Box
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Link
          color="textSecondary"
          component={RouterLink}
          to="./new"
          variant="body2"
        >
          <Button
            color="primary"
            fullWidth
            variant="contained"
            data-cy="new-email-button"
          >
            {t("mail.sidebar.new")}
          </Button>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 2,
          pr: 2
        }}
      >
        <List>
          <MailLabel
            label={{
              id: "sent",
              name: t("mail.sidebar.sent"),
              totalCount: 0,
              type: "system_label",
              unreadCount: 0
            }}
          />
        </List>
      </Box>
    </div>
  );

  return (
    <>
      <Hidden mdDown>
        <MailSidebarDesktop variant="permanent">{content}</MailSidebarDesktop>
      </Hidden>
      <Hidden mdUp>
        <MailSidebarMobile
          ModalProps={{ container: () => containerRef.current }}
          onClose={handleCloseSidebar}
          open={isSidebarOpen}
          variant="temporary"
        >
          {content}
        </MailSidebarMobile>
      </Hidden>
    </>
  );
};

MailSidebar.propTypes = {
  containerRef: PropTypes.any.isRequired
};

export default MailSidebar;
