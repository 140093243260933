/* eslint-disable */

import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  Hidden,
  IconButton
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import ChevronRightIcon from "../../icons/ChevronRight";
import EventContext from "../../contexts/EventContext";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import SidebarContext from "../../contexts/SidebarContext";
import MenuIcon from "../../icons/Menu";

const EventBreadcrumb = ({ match }) => {
  const { event } = useContext(EventContext);

  return <span>{event ? event.name : ""}</span>;
};

const DashboardBreadcrumbs = () => {
  const { t } = useTranslation();

  const { setIsSidebarMobileOpen } = useContext(SidebarContext);

  const routes = [
    {
      path: "",
      breadcrumb: t("global.page.home")
    },
    { path: "/account", breadcrumb: t("global.page.account") },
    { path: "/account/security", breadcrumb: t("global.page.security") },
    {
      path: "/event/:eventId",
      breadcrumb: EventBreadcrumb
    },
    { path: "/event/:eventId/users", breadcrumb: t("global.page.users") },
    {
      path: "/event/:eventId/scheduling",
      breadcrumb: t("global.page.scheduling")
    },
    {
      path: "/event/:eventId/control-panel",
      breadcrumb: t("global.page.controlPanel")
    },
    {
      path: "/event/:eventId/applications",
      breadcrumb: t("global.page.applications")
    },
    {
      path: "/event/:eventId/files",
      breadcrumb: t("global.page.files")
    },
    {
      path: "/event/:eventId/statistics",
      breadcrumb: t("global.page.statistics")
    },
    { path: "/event/:eventId/form", breadcrumb: t("global.page.formCreate") },
    { path: "/event/:eventId/email", breadcrumb: t("global.page.mail.label") },
    {
      path: "/event/:eventId/email/new",
      breadcrumb: t("global.page.mail.new")
    },
    {
      path: "/event/:eventId/email/sent",
      breadcrumb: t("global.page.mail.sent")
    }
  ];
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ["/event", "/event/:eventId/feed"]
  });

  return (
    <>
      <Box sx={{ p: 1, backgroundColor: "background.paper" }}>
        <Box sx={{ my: 1, alignItems: "center", display: "flex" }}>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={setIsSidebarMobileOpen}>
              <MenuIcon fontSize="small" />
            </IconButton>
          </Hidden>
          <Box sx={{ ml: 2 }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
            >
              {breadcrumbs.map(({ match, breadcrumb }) => (
                <Link
                  key={match.pathname}
                  color="textPrimary"
                  component={RouterLink}
                  to={match.pathname}
                  variant="subtitle2"
                >
                  {breadcrumb}
                </Link>
              ))}
            </Breadcrumbs>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default DashboardBreadcrumbs;
