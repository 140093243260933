/* eslint-disable */

import { Box, Button, Container, Typography } from "@material-ui/core";

import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import gtm from "../lib/gtm";
import { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const AuthorizationRequired = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t("error.accessDenied.title")} ${t(
          "global.helmetName"
        )}`}</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          px: 3,
          py: 2
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            {t("error.accessDenied.title")}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            {t("error.accessDenied.description")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              to="/"
              variant="outlined"
            >
              {t("global.button.backToHome")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error401_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthorizationRequired;
