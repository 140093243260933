/* eslint-disable */
import {
  addDays,
  endOfDay,
  setHours,
  setMinutes,
  startOfDay,
  subDays
} from "date-fns";
import { mock } from "../lib/axios";
import createResourceId from "../utils/createResourceId";

const cloneDeep = (data) => JSON.parse(JSON.stringify(data));

const now = new Date();

let events = [
  {
    id: "5e8882e440f6322fa399eeb8",
    allDay: false,
    color: "#43a048",
    description: "",
    end: setHours(setMinutes(subDays(now, 3), 0), 18),
    start: setHours(setMinutes(subDays(now, 3), 30), 15),
    title: "Kickoff meeting"
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    allDay: false,
    color: null,
    description: "",
    end: setHours(setMinutes(subDays(now, 2), 30), 15),
    start: setHours(setMinutes(subDays(now, 2), 0), 12),
    title: "Planning meeting - Information group"
  }
];

mock.onGet("/api/calendar/events").reply(200, { events });

mock.onPost("/api/calendar/events/new").reply((request) => {
  try {
    const { allDay, description, end, start, title, selected } = JSON.parse(
      request.data
    );

    // Make a deep copy
    const clonedEvents = cloneDeep(events);

    // Create the new event
    const event = {
      id: createResourceId(),
      allDay,
      description,
      end,
      start,
      title,
      selected
    };

    // Add the new event to events
    clonedEvents.push(event);

    // Save changes
    events = clonedEvents;

    return [200, { event }];
  } catch (err) {
    console.error("[Mock]: ", err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/calendar/events/update").reply((request) => {
  try {
    const { eventId, update } = JSON.parse(request.data);

    // Make a deep copy
    const clonedEvents = cloneDeep(events);

    // Find the event that will be updated
    const event = events.find((_event) => _event.id === eventId);

    if (!event) {
      return [404, "Event not found"];
    }

    // Update the event
    Object.assign(event, update);

    // Save changes
    events = clonedEvents;

    return [200, { event }];
  } catch (err) {
    console.error("[Mock]: ", err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/calendar/events/remove").reply((request) => {
  try {
    const { eventId } = JSON.parse(request.data);

    // Make a deep copy
    const clonedEvents = cloneDeep(events);

    // Find the event that will be removed
    const event = events.find((_event) => _event.id === eventId);

    if (!event) {
      return [404, "Event not found"];
    }

    events = events.filter((_event) => _event.id !== eventId);

    // Save changes
    events = clonedEvents;

    return [200, { eventId }];
  } catch (err) {
    console.error("[Mock]: ", err);
    return [500, { message: "Internal server error" }];
  }
});
