/* eslint-disable */

import { useContext } from "react";

import AccessDenied from "src/pages/AccessDenied";
import EventContext from "../../contexts/EventContext";
import PropTypes from "prop-types";

const RoleGuard = (props) => {
  const { children, roleLevels } = props;

  const { eventUser, isInitialized, isAuthenticated } =
    useContext(EventContext);

  if (!isInitialized || !isAuthenticated) {
    return <></>;
  }

  if (!eventUser.hasRole(roleLevels)) {
    return <AccessDenied />;
  }

  return <>{children}</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.string
};

export default RoleGuard;
