import { combineReducers } from "@reduxjs/toolkit";
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as mailReducer } from "../slices/mail";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  mail: mailReducer
});

export default rootReducer;
