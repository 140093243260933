/* eslint-disable */

import { useContext } from "react";

import { Outlet } from "react-router-dom";
import SidebarContext from "../contexts/SidebarContext";
import { experimentalStyled } from "@material-ui/core/styles";
import Navbar from "./Navbar";

const NavbarLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

const NavbarLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
});

const NavbarLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
});

const NavbarLayoutContent = experimentalStyled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
  paddingTop: 64
}));

const NavbarLayout = () => {
  const { setIsSidebarMobileOpen } = useContext(SidebarContext);

  return (
    <NavbarLayoutRoot>
      <Navbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <NavbarLayoutWrapper>
        <NavbarLayoutContainer>
          <NavbarLayoutContent>
            <Outlet />
          </NavbarLayoutContent>
        </NavbarLayoutContainer>
      </NavbarLayoutWrapper>
    </NavbarLayoutRoot>
  );
};

export default NavbarLayout;
